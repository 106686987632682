import React, {Component} from "react";

class Reviews extends Component{
    render(){
        return(
            <main>
                <h2>Reviews</h2>
                <p>Selected reviews of books I've read.</p>
                <div class="review-wrapper">
                    <div class="review-header">
                        <h3>The Dharma Bums</h3>
                        <p><i>Jack Kerouac</i></p>
                    </div>
                    <div class="review-content">
                        <div class="review-image">
                            <img src="https://upload.wikimedia.org/wikipedia/en/6/63/DharmaBums.JPG" />
                        </div>
                        <div class="review-text">
                            <em>2.5/5 stars</em>
                            <p>I had a strong interest in the Beats some years ago, though it was mostly an interest in Kerouac. Burroughs, Ginsberg, and some others I intend to get around to more thoroughly (and indeed I've gained more of an appreciation for their less Beat-y stuff ambiently). I have, just to make things perfectly clear, a certain respect for Jack Kerouac. In my experience he gets a lot of stick rather unfairly from readers who have a lot of surface criticisms to make. I frequently find myself somewhat defensive about Kerouac.</p>
                            <p>You can enjoy reading a book and give it a 2.5-star review, is what I'm trying to say.</p>
                            <p>I first read On The Road in high school (it's unclear to me how I was such a fast reader then), and then Big Sur in college, and read a few of his shorter poems while reading this book. (Incidentally, I read virtually nothing for fun between 2019 and 2022 [Kerouac or otherwise], because I found Big Sur to be such a slog that I didn't want to read again until I read Murakami's The Wind-up Bird Chronicle. This, I believe, is an anecdote that says way more about me than I had intended.) I say all of this to suggest that The Dharma Bums is the most focused of these three Kerouac novels, which helps it a lot. This section of Ray Smith's (Kerouac's stand-in for this novel) life - the slice charting his hike up Matterhorn to the end of his time on Desolation - has the trappings of a dramatic arc, and a little bit of a turn in the middle.</p>
                            <p>The below section discusses some spoilers, and makes reference to a suicide.</p>
                            <details>
                                <summary>Suicide</summary>
                                <p>Right - the turn in the middle. It's very difficult to approach the character of Rosie the same way one would have in 1958 for a number of reasons, which are mostly down to the way we talk about mental illness and suicidality in the 2020s. Beyond that, it's similarly difficult for me to approach Rosie without bringing along my fragile communist sensibilities. So if you'll excuse me...</p>
                                <p>I suspect that at least part of what Gary Snyder (who is Japhy Ryder in the novel) means when he talks about misogyny in this novel stems from the way Rosie is spoken about. A woman who is clearly (both from Ray Smith's and my perspective) having a mental health episode kills herself, and Ray takes a remarkably patronizing tone about it for the remainder of the book. Mental health crises, especially in women, were not famous for being handled especially well in 1958 America - but I find this particularly hard to stomach.  Seemingly the one thing Smith can ever say about Rosie is that it's so sad she wasn't enlightened like him. And every time he had something to say about her, my feeling of the book got worse and worse.</p>
                                <p>Of course, if you're familiar with Kerouac's work, there's one other dimension to this that's deeply uncomfortable. Rosie, of course, was a cipher for a real women who lived. Fictionalizing her death only to write about it like this is, frankly, unacceptable. I really can't stress enough how quickly this changed my opinion of the novel.</p>
                                <p>I would suggest this stems - in large part - from Kerouac's history of speaking rather patronizingly about women (and others) who don't share his outlook on life in his novels. It's an unfortunate pill a Kerouac reader has to swallow. But Rosie's suicide is also (given the subject matter of the book) wrapped up in Kerouac's thoughts about Buddhism. It seems that Kerouac, quite understandably, has trouble reconciling Rosie's suicide with his Buddhist beliefs. For most people, death causes a serious reckoning between the material and the ideal. (Frankly, it would be upsetting if Kerouac didn't have anything to say about it, but what he comes up with is only the next worse option.) Smith's insistence that Rosie just didn't understand his proselytizing doesn't just insult Natalie Jackson, it also cheapens his Buddhism.</p>
                                <p>Over the course of the novel, and you may be forgiven for thinking otherwise, Kerouac's ideas about Buddhism don't really ever change. Most of the revelations and insights he comes to over the course of the book are variations on the same theme - the same theme he's already arrived at by the time the novel starts up. (This, I'd suggest, is a symptom of a larger problem in Kerouac's writing - the inability to think terribly critically. Things are "good" in a Kerouac novel when he's feeling good, and they're "bad" when he feels bad. Then, once the event is written down on the page, he never casts it in a new light, or changes how he feels about it. This turns Kerouac's novels into something of a house of cards, where anything prickly or uncomfortable is easier to turn into a precarious new layer rather than deal with the contradiction and have to rebuild. In other words, and this is the most damning thing I can say about Kerouac, is that he has a lot to say but very little of it demands serious thought to digest. That's fine when you're writing about travel and the scenes of Desolation, or Matterhorn; but it crashes up really painfully against Buddhism.)</p>
                                <p>I should mention here that I'm no Buddhist, not really, so the contours of Kerouac's Buddhism and whether or not it's "good Buddhism" isn't something I can speak definitively on. I'm told there's a book by John Suiter called Poets on the Peaks that gets into Gary Snyder's thoughts on the way Buddhism appears in the novel, so I'll leave that to Snyder. And in fairness to Kerouac, the impression one gets of Buddhism through reading this novel is a wide and varied one. It's just that Kerouac's brand of it leaves a bad taste in my mouth.</p>
                            </details>
                            <p>Having said that, the rest of the book is actually quite a focused bit of Kerouac doing what he's good at. Kerouac's travel and nature sequences are great (although it's sometimes difficult to stomach his stray thoughts on money, sustainability, and trade unions from a more materialist 2024 point of view), and his prose is as always enjoyable to read. The Dharma Bums is a good idea, but it's fully punctured by the events of the middle, and it's hard to say after finishing it that it was worthwhile.</p>
                            <p>P.S.: My version of the book has cartoons on the jacket drawn by famous Norwegian cartoonist Jason. They're wonderful cartoons. You can find pictures of them online. For this reason, my copy of The Dharma Bums is my favorite print version of a Kerouac novel I own.</p>
                        </div>
                    </div>
                </div>
                <div class="review-wrapper">
                    <div class="review-header">
                        <h3>Kafka on the Shore</h3>
                        <p><i>Haruki Murakami</i></p>
                    </div>
                    <div class="review-content">
                        <div class="review-image">
                            <img src="https://upload.wikimedia.org/wikipedia/en/1/12/Kafkaontheshore.jpg" />
                        </div>
                        <div class="review-text">
                            <em>4/5 stars</em>
                            <p>Like quite a few other Murakami novels, if you're not already a fan, this one may not convince you. (As you can see, I have rated it at a four, which should give you a useful approximation of where I stand.) However, I have to say this is one of his more direct tomes.</p>
                            <p>Also in keeping with his other work, if I described the plot to you literally, I would be hiding from you the fun of reading Murakami: "A boy runs away from home, and shortly after, his father is murdered. The police come looking for him, so he spends most of the rest of the book hiding in a library." So, it's mostly useless to recount the events of the story. </p>
                            <p>If you like this book, it might be for the following reasons:</p>
                            <ul>
                                <li>This is one of his more diverse casts. Although I worry that the character of Nakata may lean a bit too close to "infantilized magic mentally disabled person," his adventure with Hoshino is fun. The character of Oshima is, in general, a remarkably respectful handling of a trans man (and as someone who's accused Murakami of being something of a gender essentialist, I must give him his flowers).</li> 
                                <li>The loose backbone of a fable retelling (Oedipus) gives the story a backbone it might otherwise lack.</li>
                                <li>It's fun. While reading 1Q84 last year,  which I also enjoyed, the split perspectives occasionally meant I was forced to interrupt an otherwise enjoyable plot line with an aside I really didn't care for. Here, I almost never felt that way. You could argue, though, that this is a function of the comparative length of the novel.</li>
                            </ul>
                            <p>If you don't like this book, it may be for these reasons:</p>
                            <ul>
                                <li>The sexual content of this novel will be immediately alienating to those who aren't familiar with Murakami's oeuvre, and perhaps even to some who are. Details inside spoiler: <details><summary>Dubiously consensual and non-consensual sex</summary>the main character, who is 15, is masturbated to climax by his older sister (dubious consent), then has sex with his mother, and then rapes his sister.</details></li>
                                <li>I've mentioned Nakata, but the scene where Oshima's transness is introduced is like pulling teeth. Honestly, if there's a redeeming facet of this scene, it's that it's so absurd it's funny.</li>
                                <li>Finally, like almost every other Murakami novel, it's still laden with metaphor and symbolism. Don't go into this one expecting an easy point-A-to-point-B.</li>
                            </ul>
                            <p>If this book loses a star, it's mostly because of Kafka (the Oshima scene notwithstanding). I didn't find him quite as compelling as other Murakami protagonists. Still, Kafka on the Shore is a fine effort from the author.</p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Reviews;