import React, {Component} from "react";
import bingoGen from "../js/bingoGen"

class Bingo extends Component{

    constructor(){
        super()
        this.state = {
            bingoData: bingoGen(),
        }
    }

    refresh(){
        this.setState({bingoData: bingoGen()});
    }

    render(){
        return(
            <main>
                <h2>Inoue Bingo</h2>
                <p>Of the more famous toku writers, Toshiki Inoue is the most infamous. Enjoy his work (particularly the new Super Sentai season, <i>Avataro Sentai Donbrothers</i>) with this bingo sheet. It's designed with <i>Donbrothers</i> in mind, but can easily be used for his other seasons with minimal changes.</p>
                <table className="bingoTable">
                    <thead>
                        <th>B</th>
                        <th>I</th>
                        <th>N</th>
                        <th>G</th>
                        <th>O</th>
                    </thead>
                    <tbody>
                        {this.state.bingoData.map((val, key) => {
                            return(
                                <tr>
                                    {val.map((v, k) => {
                                        return(
                                            <td><p>{v}</p></td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="refresh">
                    <button className="btn-center" onClick={()=>{this.refresh()}}><h2>Click to generate a new bingo card.</h2></button>
                </div>
            </main>
        );
    }
}

export default Bingo;