function bingoGen(){

    var cardOptions = [
        "Yellow gets snubbed",
        "Someone dies",
        "Villain arc",
        "Bury your gays",
        "Bonkers mid-season arc",
        "Love triangle(s)",
        "Unintentional sexual tension",
        "Awful couples",
        "Secret nonhuman protagonist",
        "Memory loss",
        "Low-effort rehabilitation arc",
        "Obvious toy marketing",
        "Evil queer-coded monster",
        "Flashbacks",
        "Banger music",
        "Hot monster ally",
        "Time travel",
        "Not explaining major plot points outside of supplemental material",
        "Misogyny",
        "Goroawase",
        "Christian aesthetics",
        "Kenji Matsuda",
        "Complicated parent-child feelings",
        "Angst",
        "Characters not sharing information",
        "Henshin device changes hands",
        "Evil little boy",
        "Wife trauma",
        "Mistaken identity",
        "Character thrown into water",
        "Malewife"
    ]

    var board = [[],[],[],[],[]]

    var shuffledOptions = cardOptions;
    shuffledOptions.sort(function(a, b){return 0.5 - Math.random()});

    for(var i=0; i<5; i++){
        for(var j=0; j<5; j++){
            board[i][j] = shuffledOptions.pop();
        }
    }

    board[2][2] = "Extremely hateable rival character (FREE)"

    console.log(board)
    return(board);
}

export default bingoGen;