import React, {Component} from "react";

class Links extends Component{
    render(){
        return(
            <main>
                <h2>MY LINKS</h2>
                <ul>
                    <li><a href="https://ia-ia-ia.world">IA IA IA.</a> I'm hosting my comics here.</li>
                    <li><a href="https://gradia.edsite.black">A quick gallery for NPCs I wired up for my Dungeons and Dragons game.</a></li>
                </ul>
                <h2>OTHER LINKS</h2>
                <ul>
                    <li><a href="https://tigers.best">My friend Jade's site.</a></li>
                </ul>
                <h2>games</h2>
                <p>I used to publish tabletop games and related materials on <a href="https://bone-mouse.itch.io">Itch.</a></p>
                <iframe className="itchFrame" title="Escherian" src="https://itch.io/embed/747594?linkback=true&amp;bg_color=222222&amp;fg_color=eeeeee&amp;border_color=363636"  frameBorder="0"><a href="https://bone-mouse.itch.io/escherian-industrial-revolution">Escherian Industrial Revolution by Bone Mouse Productions</a></iframe><br />
                <iframe className="itchFrame" title="Carrow" src="https://itch.io/embed/804581?linkback=true&amp;bg_color=222222&amp;fg_color=eeeeee&amp;border_color=363636" frameBorder="0"><a href="https://bone-mouse.itch.io/the-carrow-cookbook">The Carrow Cookbook (Second Edition) by Bone Mouse Productions</a></iframe>
            </main>
        );
    }
}

export default Links;