import React, {Component} from "react";

class Error extends Component{
    render(){
        return(
            <main>
                <h3>404</h3>
                <p>This URL doesn't exist. Perhaps it was spelled wrong.</p>
            </main>
        )
    }
}

export default Error;