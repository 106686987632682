import React, {Component} from "react";

class Gradia extends Component{

        state = {
            selectedStory: {
                title: "",
                story: "",
                stinger: "",
                act: "",
                features: []
            },
            selectedId: null
        }

    componentDidMount(){
        fetch(`https://gradia.edsite.black/api/gradia/story`)
        .then((res) => res.json())
        .then((json) => {
            this.setState({selectedStory: json[json.length - 1]});
        })
    };

    render(){
        
        return(
            <main>
                <h2>Gradia Update</h2>
                <h4>{this.state.selectedStory.title}</h4>
                <blockquote>
                {this.state.selectedStory.story.split("\n").map((story, index) => {
                    return <p key={index}>{story}</p>
                })}
                </blockquote>
            </main>
        );
    }
}

export default Gradia;